import { extendBlockerStylesheet, extendIndividualBlockerStylesheet } from "@devowl-wp/web-cookie-banner";
import { useBlocker, useStylesheet } from "../../contexts";

/**
 * Create a stylesheet extension for each rendered visual content blocker, and one stylesheet common
 * for all visual content blockers.
 */
function useBlockerStylesheet(stylesheet) {
  const {
    blocker
  } = useBlocker();
  const thisStylesheet = stylesheet || useStylesheet();
  return thisStylesheet.extend(...extendIndividualBlockerStylesheet(thisStylesheet.reactRootSymbol, blocker), true).extend(...extendBlockerStylesheet);
}
export { useBlockerStylesheet };