import { mapValueSuffixPx } from "@devowl-wp/web-scoped-css";
const ZINDEX = 999999999;
const extendBlockerHeroStylesheet = [Symbol("extendBlockerHeroStylesheet"), (_ref, _ref2) => {
  let {
    jsx,
    jsxControl,
    control,
    boolIf,
    boolNot,
    boolSwitch,
    boolOr,
    rule,
    varName,
    nestedQuery,
    className
  } = _ref;
  let {
    unsetDialogStyles,
    isMobile,
    visualThumbnail: {
      width,
      height,
      forceRatioIsset,
      forceRatio,
      hide,
      titleType
    },
    blocker: {
      visualContentType,
      visualBlur,
      isVisualDarkMode,
      presetId
    },
    bodyDesign: {
      acceptAllBg
    }
  } = _ref2;
  const isAudioPlayer = visualContentType("is-audio-player");
  const isVimeo = [visualContentType("is-video-player"), presetId("is-vimeo"), titleType("is-top")];
  const [HeroWrapper] = jsx("div", {
    classNames: "hero-wrapper",
    aspectRatio: boolIf({
      when: boolNot(isAudioPlayer),
      then: {
        when: forceRatioIsset("is-set"),
        then: `1 / calc(${forceRatio()} / 100)`,
        or: `${width()} / ${height()}`
      }
    }),
    position: "relative",
    height: boolIf(isAudioPlayer, "100px"),
    background: boolIf(isVisualDarkMode, `linear-gradient(315deg, #2f3237 1%, #5e5e5e 100%)`, `linear-gradient(hsla(0, 0%, 89%, 1) 1%, hsla(0, 0%, 97%, 1) 100%)`)
  });
  const [HeroBackgroundWrapper] = jsx("div", {
    classNames: "hero-bg-wrapper",
    width: boolIf(isAudioPlayer, "100px", "100%"),
    overflow: boolIf(isAudioPlayer, "hidden"),
    position: "absolute",
    inset: "0px"
  });
  const [HeroBackground] = jsx("img", {
    classNames: "hero-bg",
    objectFit: "cover",
    position: "absolute",
    inset: "0px",
    width: "100%",
    height: "100%",
    filter: `blur(${visualBlur()})`
  }, {
    loading: "lazy"
  });
  const [HeroTitle] = jsx("div", {
    display: boolIf(hide("has-title"), "none"),
    classNames: "hero-title",
    padding: "5px 10px",
    maxWidth: "calc(100% - 140px)",
    fontSize: "15px",
    overflow: "hidden",
    textDecoration: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    position: boolIf(titleType("is-top"), "absolute"),
    textAlign: boolIf(titleType("is-center"), "center"),
    color: boolSwitch([[isAudioPlayer, boolIf(isVisualDarkMode, "white", "black")], [isVimeo, "rgb(0, 173, 239)"]], "white"),
    textShadow: boolSwitch([[isAudioPlayer, boolIf(isVisualDarkMode, "1px 1px 1px black", "1px 1px 1px white")], [isVimeo, "none"]], "1px 1px 1px black"),
    fontWeight: boolIf(isVimeo, "bold"),
    top: boolIf(isVimeo, "20px", "10px"),
    background: boolSwitch([[isAudioPlayer, "none"], [titleType("is-center"), "#0000007d"], [isVimeo, "#00000059"]], "none"),
    left: boolSwitch([[isAudioPlayer, "110px"], [isVimeo, "20px"]], "10px"),
    marginBottom: boolIf(titleType("is-center"), "15px")
  });
  const [HeroContainer] = jsx("div", {
    classNames: "hero-container",
    cursor: "pointer",
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: boolIf(boolOr([hide("has-overlay"), isAudioPlayer]), "none", "rgb(0 0 0 / 38%)"),
    boxShadow: boolIf(boolOr([hide("has-title"), titleType("is-center"), isAudioPlayer]), "none", "inset 0 115px 60px -60px rgb(0 0 0 / 77%)")
  });

  // Extends from common button "accept-all"
  const [, heroButton] = rule({
    classNames: "hero-button",
    flex: "none !important",
    boxShadow: "rgb(0 0 0 / 15%) 0px 0px 100px 30px, rgb(0 0 0 / 40%) 0px 2px 5px 1px",
    zIndex: 9
  });
  const playButtonScalingFactor = varName();
  const [HeroPlayButton] = jsx("div", {
    classNames: "hero-play-wrapper",
    cursor: "pointer",
    zIndex: 9,
    borderRadius: boolIf(isAudioPlayer, "99px", "5px"),
    position: boolIf(isAudioPlayer, "absolute"),
    left: boolIf(isAudioPlayer, "120px"),
    top: boolIf(isAudioPlayer, "49px"),
    padding: boolIf(isAudioPlayer, "10px 10px 10px 13px", "15px 30px"),
    background: boolSwitch([[isAudioPlayer, boolIf(isVisualDarkMode, "#111213", acceptAllBg())], [isVimeo, "rgb(0 0 0 / 70%)"]], acceptAllBg()),
    [playButtonScalingFactor]: boolIf(isAudioPlayer, "0.2", "0.3"),
    pseudos: {
      ":hover": {
        background: `rgb(26 183 234 / 100%)`
      },
      ":after": {
        content: '""',
        display: "block",
        background: "transparent",
        boxSizing: "border-box",
        width: "0px",
        height: `calc(60px * var(${playButtonScalingFactor}))`,
        borderColor: `transparent transparent transparent white`,
        transition: "100ms all ease",
        cursor: "pointer",
        borderStyle: "solid",
        borderWidth: `calc(40px * var(${playButtonScalingFactor})) 0 calc(40px * var(${playButtonScalingFactor})) calc(60px * var(${playButtonScalingFactor}))`
      }
    }
  });
  const heroOverlayAnimation = varName().substr(2);
  nestedQuery(`@keyframes ${heroOverlayAnimation}`, {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  });
  const [HeroOverlay] = jsx("div", {
    classNames: "hero-db-overlay",
    position: "fixed",
    inset: "0px",
    minHeight: "100vh",
    zIndex: ZINDEX,
    background: "#000000cf",
    animation: `${heroOverlayAnimation} 0.3s`
  });

  /**
   * When the visual content blocker is too small, automatically force a width of the overlay.
   */
  const overrideHeroOverlayWidth = className();
  const minimumHeroOverlayWidth = 450;
  const HeroOverlayBlocker = jsxControl("dialog", control({
    rectTop: 0,
    rectLeft: 0,
    rectWidth: 0
  }, {
    rectTop: mapValueSuffixPx,
    rectLeft: mapValueSuffixPx,
    rectWidth: mapValueSuffixPx
  }, _ref3 => {
    let {
      rectLeft,
      rectTop,
      rectWidth
    } = _ref3;
    const [, c] = rule({
      classNames: ["hero-db-container", unsetDialogStyles],
      position: "absolute",
      left: rectLeft(),
      top: rectTop(),
      padding: boolIf(isMobile, "20px 5px", "20px 30px"),
      boxSizing: "border-box",
      zIndex: ZINDEX,
      animation: `${heroOverlayAnimation} 0.3s`,
      display: "flex",
      width: rectWidth(),
      pseudos: {
        [`.${overrideHeroOverlayWidth}`]: {
          width: `${minimumHeroOverlayWidth}px`,
          left: "50%",
          maxWidth: "calc(100vw - 40px)",
          transform: "translateX(-50%)"
        }
      }
    });
    return c;
  }), {
    tabIndex: 0,
    modifyProps: (props, _ref4) => {
      let {
        rectWidth
      } = _ref4;
      props.className += rectWidth <= minimumHeroOverlayWidth ? ` ${overrideHeroOverlayWidth}` : "";
    }
  });
  return {
    HeroWrapper,
    HeroBackgroundWrapper,
    HeroBackground,
    HeroTitle,
    HeroContainer,
    heroButton,
    HeroPlayButton,
    HeroOverlay,
    HeroOverlayBlocker
  };
}];
export { extendBlockerHeroStylesheet };