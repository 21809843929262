import { HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_INLINE } from ".";
const SCRIPTS_SELECTOR = `script[src]:not([async]):not([defer]):not([${HTML_ATTRIBUTE_BLOCKER_ID}]):not([${HTML_ATTRIBUTE_INLINE}])`;

/**
 * Create this instance at the time before adding content or scripts to your DOM,
 * and call the `.wait` function after adding content or scripts to your DOM.
 */
class WaitSynchronousScripts {
  constructor() {
    this.scriptsBefore = void 0;
    this.scriptsBefore = Array.prototype.slice.call(document.querySelectorAll(SCRIPTS_SELECTOR));
  }

  /**
   * Returns the diff of scripts as Promises and are resolved when script is loaded.
   */
  diff() {
    const scriptsAfter = Array.prototype.slice.call(document.querySelectorAll(SCRIPTS_SELECTOR));
    const scriptsDiff = scriptsAfter.filter(a => this.scriptsBefore.indexOf(a) === -1);
    const promises = scriptsDiff.map(script => new Promise(resolve => {
      // Check if the script is already loaded or in browser queue (https://stackoverflow.com/a/67184038/5506547)
      const performanceEntries = performance.getEntriesByType("resource").filter(_ref => {
        let {
          name
        } = _ref;
        return name === script.src;
      }); // `getEntriesByName` is not available in all browsers

      if (performanceEntries.length > 0) {
        resolve();
      }
      script.addEventListener("load", () => {
        resolve();
      });
      script.addEventListener("error", () => {
        // At the moment, we just ignore errors
        resolve();
      });
    }));
    return promises;
  }
}
export { WaitSynchronousScripts };