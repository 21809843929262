import { extendBlockerContentStylesheet, extendCommonContentStylesheet } from "@devowl-wp/web-cookie-banner";
import { useBlocker, useBlockerStylesheet } from "../../..";
const BlockerHeader = _ref => {
  let {
    closeIcon
  } = _ref;
  const {
    hasCloseIcon,
    HeaderTitle,
    HeaderContainer
  } = useBlockerStylesheet().extend(...extendCommonContentStylesheet).extend(...extendBlockerContentStylesheet);
  const blocker = useBlocker();
  const {
    blocker: {
      name
    },
    texts: {
      blockerHeadline
    }
  } = blocker;
  return h(HeaderContainer, null, h("div", null, h(HeaderTitle, {
    className: closeIcon ? hasCloseIcon : undefined
  }, blockerHeadline.replace(/{{name}}/gi, name)), closeIcon));
};
export { BlockerHeader };