import { WINDOW_ONLOAD_UNBLOCK_REPLACEMENT } from "..";
let overwritten = false;

/**
 * Overwrite `window.rcbInitiatorOnload =` assignemnts so they get immediatly executed.
 * At the time of consent the window is definitely loaded.
 */
function applyWindowOnloadInitiator(doc) {
  // Only overwrite once
  if (overwritten) {
    return;
  }
  const w = doc.defaultView || doc.parentWindow;
  try {
    Object.defineProperty(w, WINDOW_ONLOAD_UNBLOCK_REPLACEMENT, {
      set: function (newValue) {
        if (typeof newValue === "function") {
          newValue();
        }
      },
      enumerable: true,
      configurable: true
    });
  } catch (e) {
    // Silence is golden
  }
  overwritten = true;
}
export { applyWindowOnloadInitiator };