/**
 * @see https://stackoverflow.com/a/7557433/5506547
 */
function isElementInViewport(el) {
  let partial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const {
    top,
    left,
    bottom,
    right,
    height,
    width
  } = el.getBoundingClientRect();
  const {
    innerWidth,
    innerHeight
  } = window;
  if (partial) {
    const vertInView = top <= innerHeight && top + height >= 0;
    const horInView = left <= innerWidth && left + width >= 0;
    return vertInView && horInView;
  } else {
    const {
      clientHeight,
      clientWidth
    } = document.documentElement;
    return top >= 0 && left >= 0 && bottom <= (innerHeight || clientHeight) && right <= (innerWidth || clientWidth);
  }
}
export { isElementInViewport };