const extendBlockerBodyStylesheet = [Symbol("extendBlockerBodyStylesheet"), (_ref, _ref2) => {
  let {
    jsx,
    rule,
    boolIf,
    className
  } = _ref;
  let {
    isDialog,
    isMobile,
    blockerOverwrites: {
      borderWidth,
      borderColor
    },
    design: {
      bg,
      fontSize
    },
    layout: {
      dialogBorderRadius
    },
    bodyDesign: {
      padding,
      descriptionFontSize,
      descriptionInheritFontSize
    },
    boolLargeOrMobile
  } = _ref2;
  const showFooter = className();
  const [Container] = jsx("div", {
    classNames: "body-container",
    background: bg(),
    padding: boolLargeOrMobile(padding, boolIf),
    lineHeight: "1.4",
    borderStyle: "solid",
    borderColor: borderColor(),
    borderWidth: borderWidth(),
    borderTopWidth: "0px",
    borderRadius: boolIf(isDialog, `0 0 ${dialogBorderRadius()} ${dialogBorderRadius()}`),
    pseudos: {
      ">div": {
        margin: "auto"
      },
      [`<.${showFooter} `]: {
        borderBottom: "0px",
        borderRadius: "0px"
      },
      ">div>div,>div>a:last-of-type": {
        marginBottom: boolIf(isMobile, "10px", "15px")
      }
    }
  });
  const [CookieScroll] = jsx("div", rule({
    classNames: "cookie-scroll",
    fontSize: boolIf(descriptionInheritFontSize, boolLargeOrMobile(fontSize, boolIf), boolLargeOrMobile(descriptionFontSize, boolIf)),
    textAlign: "left",
    marginBottom: "10px",
    maxHeight: "400px",
    overflowY: "scroll",
    paddingRight: "10px"
  }));
  return {
    Container,
    showFooter,
    CookieScroll
  };
}];
export { extendBlockerBodyStylesheet };