import { extendBlockerContentStylesheet } from "@devowl-wp/web-cookie-banner";
import { useCommonFooterLinks, useBlockerStylesheet } from "../../..";
const BlockerFooter = () => {
  const {
    FooterContainer
  } = useBlockerStylesheet().extend(...extendBlockerContentStylesheet);
  const {
    rows: links,
    render: renderLinks
  } = useCommonFooterLinks({
    putPoweredByLinkInRow: 1
  });
  return h(FooterContainer, null, h("div", null, renderLinks(links)));
};
export { BlockerFooter };