const extendBlockerContentStylesheet = [Symbol("extendBlockerContentStylesheet"), (_ref, _ref2) => {
  let {
    jsx,
    boolIf,
    boolSwitch
  } = _ref;
  let {
    boolLargeOrMobile,
    blockerOverwrites: {
      borderColor,
      borderWidth
    },
    isDialog,
    design: {
      bg,
      textAlign
    },
    layout: {
      dialogBorderRadius
    },
    footerDesign,
    headerDesign
  } = _ref2;
  const headerContainerBorderHeight = boolLargeOrMobile(headerDesign.borderWidth, boolIf);
  const [HeaderContainer] = jsx("div", {
    classNames: "header-container",
    position: "relative",
    background: boolIf(headerDesign.inheritBg, bg(), headerDesign.bg()),
    borderRadius: boolIf(isDialog, `${dialogBorderRadius()} ${dialogBorderRadius()} 0 0`),
    padding: boolLargeOrMobile(headerDesign.padding, boolIf),
    paddingBottom: `calc(${headerContainerBorderHeight} + ${boolLargeOrMobile(headerDesign.padding, boolIf, 2)})`,
    borderWidth: borderWidth(),
    borderStyle: "solid",
    borderColor: borderColor(),
    borderBottom: "unset",
    pseudos: {
      ":after": {
        content: "''",
        display: "block",
        position: "absolute",
        left: "0px",
        right: "0px",
        bottom: "0px",
        background: headerDesign.borderColor(),
        height: headerContainerBorderHeight
      },
      ">div": {
        margin: "auto",
        display: "flex",
        alignItems: "center",
        position: "relative",
        textAlign: boolIf(headerDesign.inheritTextAlign, textAlign("val"), headerDesign.textAlign("val")),
        justifyContent: boolIf(headerDesign.inheritTextAlign, boolSwitch([[textAlign("is-center"), "center"], [textAlign("is-right"), "flex-end"]]), boolSwitch([[headerDesign.textAlign("is-center"), "center"], [headerDesign.textAlign("is-right"), "flex-end"]]))
      }
    }
  });
  const footerContainerBorderHeight = boolLargeOrMobile(footerDesign.borderWidth, boolIf);
  const [FooterContainer] = jsx("div", {
    classNames: "footer-container",
    fontWeight: footerDesign.fontWeight(),
    color: footerDesign.fontColor(),
    position: "relative",
    padding: boolLargeOrMobile(footerDesign.padding, boolIf),
    paddingTop: `calc(${footerContainerBorderHeight} + ${boolLargeOrMobile(footerDesign.padding, boolIf, 0)})`,
    background: boolIf(footerDesign.inheritBg, bg(), footerDesign.bg()),
    fontSize: boolLargeOrMobile(footerDesign.fontSize, boolIf),
    textAlign: boolIf(footerDesign.inheritTextAlign, textAlign("val"), footerDesign.textAlign()),
    borderRadius: boolIf(isDialog, `0 0 ${dialogBorderRadius()} ${dialogBorderRadius()}`),
    borderWidth: borderWidth(),
    borderStyle: "solid",
    borderColor: borderColor(),
    borderTop: "unset",
    pseudos: {
      ":after": {
        content: "''",
        display: "block",
        position: "absolute",
        left: "0px",
        right: "0px",
        top: "0px",
        background: footerDesign.borderColor(),
        height: footerContainerBorderHeight
      },
      ">div": {
        margin: "auto",
        lineHeight: "1.8"
      }
    }
  });
  return {
    HeaderContainer,
    FooterContainer
  };
}];
export { extendBlockerContentStylesheet };