import _extends from "@babel/runtime/helpers/extends";
import { useRef, useState, Fragment } from "react";
import { extendBlockerHeroStylesheet, extendCommonButtonsStylesheet } from "@devowl-wp/web-cookie-banner";
import { useBlocker, BlockerHeroAudioWaveFromText, BlockerHeroVideoSvgPlayButton, BlockerHeroDialog, useBlockerStylesheet, Button } from "../../..";
const BlockerHeroContainer = _ref => {
  let {
    blockerClassName,
    blockerOverlayId,
    ...rest
  } = _ref;
  const stylesheet = useBlockerStylesheet();
  const {
    HeroWrapper,
    HeroBackgroundWrapper,
    HeroBackground,
    HeroTitle,
    HeroContainer,
    heroButton,
    HeroPlayButton,
    screenReaderOnlyClass
  } = stylesheet.extend(...extendBlockerHeroStylesheet).extend(...extendCommonButtonsStylesheet);
  const blocker = useBlocker();
  const {
    texts: {
      blockerHeadline
    },
    blocker: {
      presetId,
      name,
      visualHeroButtonText,
      visualContentType,
      visualThumbnail
    }
  } = blocker;
  const ref = useRef();
  const heroContainerRef = useRef();
  const [isHover, setIsHover] = useState(false);
  const {
    url
  } = visualThumbnail;
  const title = visualThumbnail.title || name;
  const blockerHeadlineWithName = blockerHeadline.replace(/{{name}}/gi, name);
  const audioWaveText = `${visualThumbnail.title}ThiS iS jUst ANother TEXT TO reACh minIMum length` || blockerHeadlineWithName;
  const showPlayButton = ["video-player", "audio-player"].indexOf(visualContentType) > -1;
  return h(Fragment, null, h(HeroWrapper, _extends({
    ref: ref
  }, rest), h(HeroBackgroundWrapper, {
    "aria-hidden": true
  }, h(HeroBackground, {
    src: url,
    alt: title
  })), h(HeroContainer, {
    role: "presentation",
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false),
    ref: heroContainerRef
  }, h(HeroTitle, {
    "aria-hidden": true
  }, title), h("a", {
    href: "#",
    className: screenReaderOnlyClass,
    "aria-label": `${blockerHeadlineWithName}, ${title}`
  }, title), visualContentType === "audio-player" && h(BlockerHeroAudioWaveFromText, {
    text: audioWaveText
  }), visualHeroButtonText && ["audio-player", "video-player"].indexOf(visualContentType) === -1 ? h(Button, {
    type: "acceptAll",
    forceShow: true,
    busyOnClick: false,
    className: heroButton
  }, "\xA0\xA0", visualHeroButtonText, "\xA0\xA0") : showPlayButton && (presetId === "youtube" && visualContentType === "video-player" ? h(BlockerHeroVideoSvgPlayButton, {
    fill: isHover ? "#ff0808" : `rgb(0 0 0 / 70%)`
  }) : h(HeroPlayButton, null)))), h(BlockerHeroDialog, {
    blockerClassName: blockerClassName,
    blockerOverlayId: blockerOverlayId,
    heroContainerRef: heroContainerRef
  }));
};
export { BlockerHeroContainer };