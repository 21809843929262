import { useBlocker, useBlockerStylesheet } from "../..";
import { extendBlockerBodyStylesheet } from "@devowl-wp/web-cookie-banner";
import { CONTEXT_LATEST_DESIGN_VERSION } from "../../types";
const useBlockerClassName = stylesheet => {
  const {
    individualBlockerClassName,
    showFooter: showFooterClassName
  } = useBlockerStylesheet(stylesheet).extend(...extendBlockerBodyStylesheet);
  const {
    designVersion = CONTEXT_LATEST_DESIGN_VERSION
  } = useBlocker();
  const showFooter = designVersion === 1;
  return [showFooter ? showFooterClassName : undefined, stylesheet.className, individualBlockerClassName].filter(Boolean).join(" ");
};
export { useBlockerClassName };