import _extends from "@babel/runtime/helpers/extends";
import { useBlocker, useMainStylesheetForContext, useBlockerClassName, useA11yId, BlockerHeroDialog, yieldLazyLoad } from "../..";
import { useEffect } from "react";
import { StyleSheetContextFactory } from "../../contexts";
import { HTML_ATTRIBUTE_CONFIRM } from "@devowl-wp/headless-content-unblocker";
const BlockerTextContainer = yieldLazyLoad(import( /* webpackMode: "eager" */"./textContainer").then(_ref => {
  let {
    BlockerTextContainer
  } = _ref;
  return BlockerTextContainer;
}));
const BlockerHeroContainer = yieldLazyLoad(import( /* webpackMode: "eager" */"./heroContainer").then(_ref2 => {
  let {
    BlockerHeroContainer
  } = _ref2;
  return BlockerHeroContainer;
}));
const Blocker = () => {
  const stylesheet = useMainStylesheetForContext();
  const {
    description: id
  } = stylesheet.reactRootSymbol;
  const {
    recorder,
    blocker: {
      visualType,
      visualThumbnail
    },
    blockedNode
  } = useBlocker();
  const StyleSheetContext = StyleSheetContextFactory.Context();
  const useClassName = useBlockerClassName(stylesheet);
  const a11yExpanded = useA11yId(stylesheet);

  // Automatically start recorder if one exists
  useEffect(() => {
    if (recorder) {
      recorder.restart();
      return () => {
        recorder.toggle(false);
      };
    }
    return () => {
      // Silence is golden.
    };
  }, []);
  const attr = {
    id: a11yExpanded
  };
  const dialogAttr = {
    blockerClassName: useClassName,
    blockerOverlayId: `${id}-o`
  };
  return h(StyleSheetContext.Provider, {
    value: stylesheet
  }, blockedNode.hasAttribute(HTML_ATTRIBUTE_CONFIRM) ? h(BlockerHeroDialog, _extends({}, dialogAttr, {
    heroContainerRef: {
      current: blockedNode
    }
  })) : h("div", {
    className: useClassName,
    id: id
  }, process.env.PLUGIN_CTX === "pro" && visualType === "hero" && visualThumbnail ? h(BlockerHeroContainer, _extends({}, dialogAttr, attr)) : h(BlockerTextContainer, attr)));
};
export { Blocker };