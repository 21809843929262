import _extends from "@babel/runtime/helpers/extends";
import { useBlocker, useBlockerStylesheet, extendCommonButtonsStylesheet, yieldLazyLoad } from "../../..";
import { CONTEXT_LATEST_DESIGN_VERSION } from "../../types";
import { useRef } from "react";
const BlockerHeader = yieldLazyLoad(import( /* webpackMode: "eager" */"./header/header").then(_ref => {
  let {
    BlockerHeader
  } = _ref;
  return BlockerHeader;
}));
const BlockerBody = yieldLazyLoad(import( /* webpackMode: "eager" */"./body/body").then(_ref2 => {
  let {
    BlockerBody
  } = _ref2;
  return BlockerBody;
}));
const BlockerFooter = yieldLazyLoad(import( /* webpackMode: "eager" */"./footer/footer").then(_ref3 => {
  let {
    BlockerFooter
  } = _ref3;
  return BlockerFooter;
}));
const BlockerTextContainer = _ref4 => {
  let {
    closeIcon,
    ...rest
  } = _ref4;
  const blocker = useBlocker();
  const {
    designVersion = CONTEXT_LATEST_DESIGN_VERSION,
    blocker: {
      visualType,
      visualThumbnail,
      name
    },
    texts: {
      blockerHeadline
    },
    i18n: {
      skipToConsentChoices
    }
  } = blocker;
  const {
    Inner,
    Content,
    InnerBackground,
    A11ySkipToLink,
    a11yIds: {
      firstButton
    }
  } = useBlockerStylesheet().extend(...extendCommonButtonsStylesheet);
  const ref = useRef();
  return h(Inner, _extends({
    ref: ref,
    className: "wp-exclude-emoji"
  }, rest), h(A11ySkipToLink, {
    href: `#${firstButton}`,
    "aria-label": `${blockerHeadline.replace(/{{name}}/gi, name)}, ${skipToConsentChoices}`
  }, skipToConsentChoices), visualType === "wrapped" && h(InnerBackground, {
    src: visualThumbnail.url,
    alt: visualThumbnail.title,
    "aria-hidden": true
  }), h(Content, null, h(BlockerHeader, {
    closeIcon: closeIcon
  }), h(BlockerBody, null), designVersion === 1 && h(BlockerFooter, null)));
};
export { BlockerTextContainer };